import { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';

export default function ProtectedRoute({ children, shouldBeOrganization = false }) {
  const { isLoggedIn, hasOrganization, organizationId, setPrevPath } = useContext(AppContext);
  const location = useLocation();

  const shouldRender = shouldBeOrganization ? isLoggedIn && organizationId : isLoggedIn;

  useEffect(() => {
    if (!shouldRender) {
      setPrevPath(location.pathname);
    }
  }, [shouldRender, location.pathname, setPrevPath]);

  if (!shouldRender) {
    if (isLoggedIn && hasOrganization) {
      return <Navigate to={`/organizacion/dashboard/${organizationId}`} />;
    }
    if (isLoggedIn && !hasOrganization) {
      return <Navigate to={`/explora`} />;
    }
    return <Navigate to={`/auth`} />;
  }

  return children;
}
