import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Container,
  Flex,
  Link,
  Text,
  Icon,
  SimpleGrid,
  VStack,
  Divider,
  IconButton,
} from '@chakra-ui/react';
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandX,
} from '@tabler/icons-react';

import { NatoureLogo } from '../../../assets/icons/js';

const socialMedia = [
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/Natoure.org/',
    icon: IconBrandFacebook,
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/natoure.life',
    icon: IconBrandInstagram,
  },
  {
    name: 'X',
    link: 'https://twitter.com/natoureorg',
    icon: IconBrandX,
  },
  {
    name: 'LinkedIn',
    link: 'https://www.linkedin.com/company/natoure/',
    icon: IconBrandLinkedin,
  },
];

export default function Footer() {
  const { t } = useTranslation(['layout']);

  const links = [
    {
      name: t('footer.explore.title'),
      items: [
        {
          name: t('footer.explore.experiences'),
          url: '/explora/experiencias',
        },
        {
          name: t('footer.explore.gastronomy'),
          url: '/explora/gastronomia',
        },
        {
          name: t('footer.explore.hotels'),
          url: '/explora/hoteles',
        },
        {
          name: t('footer.explore.restaurants'),
          url: '/explora/restaurantes',
        },
      ],
    },
    {
      name: t('footer.destinations.title'),
      items: [
        {
          name: t('footer.destinations.anps'),
          url: '/destinos/areas-naturales',
        },
        {
          name: t('footer.destinations.destinations'),
          url: '/destinos',
        },
      ],
    },
    {
      name: t('footer.participate.title'),
      items: [
        {
          name: t('footer.participate.products'),
          url: '/productos',
        },
        {
          name: t('footer.participate.hosts'),
          url: '/anfitriones',
        },
      ],
    },
    {
      name: t('footer.aboutus.title'),
      items: [
        {
          name: t('footer.aboutus.aboutus'),
          url: '/nosotros',
        },
        {
          name: t('footer.aboutus.privacy'),
          url: '/politica-de-privacidad',
        },
        {
          name: t('footer.aboutus.terms'),
          url: '/terminos-y-condiciones',
        },
      ],
    },
  ];

  return (
    <Box bg='gray.800' color='white'>
      <Container
        as={Flex}
        flexDir='column'
        fontSize='sm'
        maxW={{ base: 'xl', lg: '5xl', xl: '7xl' }}
      >
        <SimpleGrid columns={{ base: '1', md: '2', lg: '4' }} gap='6' py={{ base: '12', lg: '24' }}>
          {links.map(({ items, name }) => (
            <VStack align='flex-start' spacing='2' key={name}>
              <Text fontSize='lg' fontWeight='semibold' mb='2'>
                {name}
              </Text>
              {items.map(item => (
                <Link
                  key={item.url}
                  as={RouterLink}
                  to={item.url}
                  _hover={{ textDecoration: 'underline' }}
                >
                  {item.name}
                </Link>
              ))}
            </VStack>
          ))}
        </SimpleGrid>

        <Divider />

        <Flex
          align={{ base: 'center', md: 'flex-start' }}
          flexDir={{ base: 'column', md: 'row' }}
          gap='6'
          justify='space-between'
          pt='12'
          pb={{ base: '32', md: '12' }}
        >
          <Box w='40'>
            <NatoureLogo color='#FDFFFC' />
          </Box>

          <Flex align={{ base: 'center', md: 'flex-end' }} flexDir='column' gap='4'>
            <Flex gap='6'>
              {socialMedia.map(item => (
                <IconButton
                  aria-label={`Go to ${item.name} profile`}
                  as='a'
                  href={item.link}
                  key={item.name}
                  p='1'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Icon as={item.icon} h='8' strokeWidth='1' w='8' />
                </IconButton>
              ))}
            </Flex>
            <Link as='a' href='mailto:conectate@natoure.org'>
              {t('footer.contactus')} conectate@natoure.org
            </Link>
            <Text>
              © {new Date().getFullYear()} Natoure. {t('footer.copyright')}
            </Text>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}
