import React from 'react';

const NatoureLogo = ({ color }) => {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 1920 650'
    >
      <g>
        <g id='Logo_Oso'>
          <path
            id='Oreja2_2_'
            fill='#18955e'
            d='M461.8,151.6c-8.5-7.6-18.7-11.5-30.6-11.5c-14.1,0-24.7,4.2-32,12.5
			c4.7,3,11.3,7.5,18.9,13.2c10.4,7.9,17.2,13.7,24.7,21.9c7.9,8.6,12.4,15.5,17.1,23.3c6.3,10.4,10.9,20.3,14.4,28.8
			c0.3-28,0-49.4,0-60.3C474.1,168.8,470,159.5,461.8,151.6z'
          />
          <path
            fill='#18955e'
            d='M423.1,190.7c-33.9-33.9-74.7-50.8-122.5-50.8h-2.9c-35.3,0-67.5,9.9-96.7,29.6c-5-19.7-17.5-29.6-37.5-29.6
			c-10.8,0-20.1,3.8-27.9,11.3c-7.5,7.8-11.3,17-11.3,27.5v130.2v1.1v71.2c0,0.3,0.1,0.5,0.1,0.8v36.6h0.2
			c1,37.1,17.8,68.9,50.6,95.4c31.6,25.5,69.5,38.9,113.2,40.6v0.2h22v-0.3c43.5-1.8,81-15.2,112.5-40.6
			c32.9-26.5,49.6-58.4,50.6-95.4h0.2v-35.8c0-0.5,0.1-1,0.1-1.6v-72.2h-0.2C472.7,263,456,223.5,423.1,190.7z M396,358.9
			c0,0.4-0.1,0.8-0.1,1.3v57.5h-0.1c-0.7,20.2-10.1,37.5-28.2,51.9c-16.6,13.4-36.2,20.7-58.8,22.1h-19.3
			c-22.5-1.5-42.2-8.7-58.8-22.1c-18.1-14.4-27.4-31.7-28.2-51.9h-0.1v-57.5c0-0.2-0.1-0.4-0.1-0.6V310h0.1
			c0.7-25,9.9-46.5,27.8-64.3c18.6-18.6,41.1-27.9,67.5-27.9h2.9c26.1,0,48.5,9.3,67.1,27.9c18.1,17.9,27.4,39.3,28.2,64.3h0.1
			L396,358.9L396,358.9z'
          />
          <path
            fill='#18955e'
            d='M267.2,378.2c-18.4,18.4-18.4,48.2,0,66.5c10.6,10.6,29.5,29.5,30.4,30.4c1.6,1.6,4.1,1.6,5.7,0
			s24.9-24.9,30.4-30.4c18.4-18.4,18.4-48.2,0-66.5C315.3,359.9,285.5,359.9,267.2,378.2z'
          />
        </g>
        <g>
          <path
            style={{ transition: 'all 0.5s' }}
            fill={color}
            d='M1064.9,284.1h-40.1v109.7c0,13,6.3,17.7,23.2,17.7h16.9v37.8c-12.2,1.2-20.8,1.6-26,1.6
			c-43.7,0-63.3-15.3-63.3-51.9V284.1h-31.5v-37.8h31.5v-45.3h49.2v45.3h40.1V284.1z'
          />
          <path
            style={{ transition: 'all 0.5s' }}
            fill={color}
            d='M1191.1,454c-63.3,0-103.4-43.7-103.4-106.2c0-62.5,40.1-106.2,103.4-106.2c64.1,0,103,42.9,103,106.2
			C1294.1,410.4,1254.8,454,1191.1,454 M1243.8,347.8c0-42.1-20.1-65.3-52.7-65.3c-33,0-52.7,23.2-52.7,65.3
			c0,42.1,19.7,65.3,52.7,65.3C1223.7,413.1,1243.8,389.9,1243.8,347.8'
          />
          <path
            style={{ transition: 'all 0.5s' }}
            fill={color}
            d='M1459.7,246.4h49.6v202.9h-48v-29.5c-15.3,22.8-36.6,34.2-63.7,34.2c-42.9,0-70.8-26-70.8-73.1V246.4h49.6
			v126.2c0,25.6,12.6,38.5,38.1,38.5c24,0,45.2-17.7,45.2-51.1V246.4z'
          />
          <path
            style={{ transition: 'all 0.5s' }}
            fill={color}
            d='M1676.8,244v45.6h-12.6c-37.4,0-58.6,18.9-58.6,56.6v103h-49.6V246.4h48v36.2c11.4-26.4,31.5-39.3,60.6-39.3
			C1669.7,243.2,1674,243.6,1676.8,244'
          />
          <path
            style={{ transition: 'all 0.5s' }}
            fill={color}
            d='M565.9,247.8l1.3,41.8c6.1-14.5,15-25.7,26.8-33.6c11.7-7.9,26.2-11.9,43.4-11.9c22.1,0,38.7,7,49.7,20.9
			c11.1,13.9,16.6,36,16.6,66.2v119.3h-10.1V330.8c0-28.1-5-47.9-15.1-59.4c-10.1-11.5-23.7-17.2-40.9-17.2
			c-21.8,0-38.3,6.5-49.4,19.6c-11.1,13-18.1,29.7-20.8,50.1v126.6h-10.1V247.8H565.9z'
          />
          <path
            style={{ transition: 'all 0.5s' }}
            fill={color}
            d='M891.2,413c-7,12.2-17.4,22.2-31.3,29.9c-13.9,7.7-30.6,11.5-50.4,11.5c-18.9,0-33.6-5.2-44.4-15.5
			c-10.7-10.3-16.1-24.2-16.1-41.7c0-17,8-31.2,24.1-42.7c16-11.5,36.1-17.2,60.2-17.2h57.9v-29.6c0-16.9-5.4-30-16.2-39.4
			c-10.8-9.4-26-14.1-45.6-14.1c-18.3,0-33.1,4.4-44.3,13.3c-11.2,8.9-16.8,20.3-16.8,34.3l-8.4-0.4l-0.4-0.9
			c-0.7-15.7,5.4-29.1,18.4-40c13-10.9,30.1-16.4,51.5-16.4c21.7,0,39.1,5.5,52.2,16.5c13.1,11,19.7,26.8,19.7,47.6v101.5
			c0,7.1,0.4,14,1.2,20.8c0.8,6.7,2.2,13.4,4,20h-11.2c-1.6-8.9-2.7-15.5-3.3-19.9c-0.6-4.4-0.8-9.2-0.8-14.3V413z M809.6,444.4
			c20.1,0,37.1-4.4,51-13.3c13.9-8.9,24.1-21.5,30.6-38v-45.5h-57.3c-21,0-38.7,4.8-53.1,14.5c-14.4,9.7-21.6,21.6-21.6,35.9
			c0,13.6,4.6,24.8,13.8,33.4C782.1,440.1,794.4,444.4,809.6,444.4'
          />
          <path
            style={{ transition: 'all 0.5s' }}
            fill={color}
            d='M1782,454.4c-23.1,0-43-9.3-59.8-28c-16.8-18.7-25.2-41.9-25.2-69.6v-12c0-29.1,8.1-53.1,24.3-72.2
			c16.2-19,36.3-28.6,60.1-28.6c22.8,0,41.5,7.5,55.9,22.5c14.4,15,21.6,35.1,21.6,60.3v15.5h-151.9v2.4v12
			c0,24.5,7.2,45.2,21.5,62.1c14.4,16.9,32.1,25.4,53.4,25.4c15.1,0,27.7-2.1,37.9-6.2c10.2-4.1,18.9-10.3,26.1-18.5l5.4,7.9
			c-8,8.9-17.7,15.6-29.1,20.1C1811,452.1,1797.5,454.4,1782,454.4 M1781.5,254.2c-20.1,0-36.8,7.3-50.2,22
			c-13.4,14.7-20.9,33-22.7,55l0.6,1.1h139.7v-6.2c0-20.6-6.2-37.7-18.7-51.4C1817.7,261,1801.4,254.2,1781.5,254.2'
          />
        </g>
        <g id='Logo_Oso_1_'>
          <path
            fill='#18955e'
            d='M267.2,378.2c-18.4,18.4-18.4,48.2,0,66.5c10.6,10.6,29.5,29.5,30.4,30.4c1.6,1.6,4.1,1.6,5.7,0
			s24.9-24.9,30.4-30.4c18.4-18.4,18.4-48.2,0-66.5C315.3,359.9,285.5,359.9,267.2,378.2z'
          />
          <path
            fill='#18955e'
            d='M423.1,190.7c-33.9-33.9-74.7-50.8-122.5-50.8h-2.9c-35.3,0-67.5,9.9-96.7,29.6c-5-19.7-17.5-29.6-37.5-29.6
			c-10.8,0-20.1,3.8-27.9,11.3c-7.5,7.8-11.3,17-11.3,27.5v130.2v1.1v71.2c0,0.3,0.1,0.5,0.1,0.8v36.6h0.2
			c1,37.1,17.8,68.9,50.6,95.4c31.6,25.5,69.5,38.9,113.2,40.6v0.2h22v-0.3c43.5-1.8,81-15.2,112.5-40.6
			c32.9-26.5,49.6-58.4,50.6-95.4h0.2v-35.8c0-0.5,0.1-1,0.1-1.6v-72.2h-0.2C472.7,263,456,223.5,423.1,190.7z M396,358.9
			c0,0.4-0.1,0.8-0.1,1.3v57.5h-0.1c-0.7,20.2-10.1,37.5-28.2,51.9c-16.6,13.4-36.2,20.7-58.8,22.1h-19.3
			c-22.5-1.5-42.2-8.7-58.8-22.1c-18.1-14.4-27.4-31.7-28.2-51.9h-0.1v-57.5c0-0.2-0.1-0.4-0.1-0.6V310h0.1
			c0.7-25,9.9-46.5,27.8-64.3c18.6-18.6,41.1-27.9,67.5-27.9h2.9c26.1,0,48.5,9.3,67.1,27.9c18.1,17.9,27.4,39.3,28.2,64.3h0.1
			L396,358.9L396,358.9z'
          />
        </g>
      </g>
    </svg>
  );
};

export default NatoureLogo;
