import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import ProtectedRoute from './components/common/ProtectedRoute';
import Loader from './components/common/Loaders/Loader';
import AvoidIfLoggedIn from './components/common/AvoidIfLoggedIn';

const Search = lazy(() =>
  import('./pages/search').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const HotelsList = lazy(() =>
  import('./pages/explora/HotelsList').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const RestaurantsList = lazy(() =>
  import('./pages/explora/Restaurants').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);

const ForgotPassword = lazy(() =>
  import('./pages/ForgotPassword').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const ResetPassword = lazy(() =>
  import('./pages/ResetPassword').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const ExperiencesMap = lazy(() =>
  import('./pages/explora/Maps/ExperiencesMap').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const OrganizationsMap = lazy(() =>
  import('./pages/explora/Maps/OrganizationsMap').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const AboutUs = lazy(() =>
  import('./pages/AboutUs').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const Trips = lazy(() =>
  import('./pages/user/Trips').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const Purchases = lazy(() =>
  import('./pages/user/Purchases').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const NewProduct = lazy(() =>
  import('./pages/organization/NewProduct').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const NewExperience = lazy(() =>
  import('./pages/organization/NewExperience').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const NewGastronomy = lazy(() =>
  import('./pages/organization/NewGastronomy').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const NewLodging = lazy(() =>
  import('./pages/organization/NewLodging').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
// const UnderConstructionAnimation = lazy(() =>
//   import('./assets/animations/components/UnderConstructionAnimation'),
// );
const OrganizationExperiences = lazy(() =>
  import('./pages/organization/Dashboard/Products/Experiences').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const OrganizationProducts = lazy(() =>
  import('./pages/organization/Dashboard/Products/Products').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const OrganizationGastronomy = lazy(() =>
  import('./pages/organization/Dashboard/Products/Dishes').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const OrganizationLodging = lazy(() =>
  import('./pages/organization/Dashboard/Products/Lodging').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const OrganizationReservations = lazy(() =>
  import('./pages/organization/Dashboard/Reservations').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const Orders = lazy(() =>
  import('./pages/organization/Dashboard/Orders').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const Team = lazy(() =>
  import('./pages/organization/Dashboard/Team/Partners.jsx').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const NewPartner = lazy(() =>
  import('./pages/organization/NewPartner.jsx').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const DashboardHome = lazy(() =>
  import('./pages/organization/Dashboard/DashboardHome').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const Home = lazy(() =>
  import('./pages/Home').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const Register = lazy(() =>
  import('./pages/organization/Register').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const Evaluation = lazy(() =>
  import('./pages/organization/Evaluation').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const Auth = lazy(() =>
  import('./pages/Auth').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const PrivacyPolicy = lazy(() =>
  import('./pages/PrivacyPolicy').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const CookiesPolicy = lazy(() =>
  import('./pages/CookiesPolicy').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const Terms = lazy(() =>
  import('./pages/Terms').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const Dashboard = lazy(() =>
  import('./pages/organization/Dashboard/Wrapper').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const ExperiencesList = lazy(() =>
  import('./pages/explora/Experiencias').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const ProductDetail = lazy(() =>
  import('./pages/products/Detail.jsx').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const DishesList = lazy(() =>
  import('./pages/explora/dishes/List').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const DishesDetail = lazy(() =>
  import('./pages/explora/dishes/Detail').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const LodgingDetail = lazy(() =>
  import('./pages/explora/lodgings/Detail').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const ExploreWrapper = lazy(() =>
  import('./pages/explora/Wrapper').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const ExperienceDetail = lazy(() =>
  import('./pages/experience/Detail').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const ExperienceCheckout = lazy(() =>
  import('./pages/experience/Checkout').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const HostsWrapper = lazy(() =>
  import('./pages/hosts/Wrapper').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);

const HostsList = lazy(() =>
  import('./pages/hosts/List').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const HostsDetail = lazy(() =>
  import('./pages/hosts/Detail').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const Profile = lazy(() =>
  import('./pages/user/Profile').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const PaymentForm = lazy(() =>
  import('./pages/organization/Dashboard/settings/PaymentsAndBilling/PaymentForm').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const BillingForm = lazy(() =>
  import('./pages/organization/Dashboard/settings/PaymentsAndBilling/BillingForm').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);

const DestinationWrapper = lazy(() =>
  import('./pages/destination/Wrapper').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const DestinationsMap = lazy(() =>
  import('./pages/destination/SustainableDestinations/Map').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const NaturalAreasList = lazy(() =>
  import('./pages/destination/NaturalAreas/List').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const SustainableDestinationsList = lazy(() =>
  import('./pages/destination/SustainableDestinations/List').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const NaturalAreasDetail = lazy(() =>
  import('./pages/destination/NaturalAreas/Detail').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const NaturalAreasMap = lazy(() =>
  import('./pages/destination/NaturalAreas/Map').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const SustainableDestinationsDetail = lazy(() =>
  import('./pages/destination/SustainableDestinations/Detail').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);

const AttractionsDetail = lazy(() =>
  import('./pages/destination/AttractionDetail.jsx').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);

const ProductsWrapper = lazy(() =>
  import('./pages/products/Wrapper').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const ProductsList = lazy(() =>
  import('./pages/products/List').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);
const ProductsMap = lazy(() =>
  import('./pages/products/Map.jsx').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default function AppRouter() {
  return (
    <Suspense fallback={<Loader cover />}>
      <SentryRoutes>
        <Route path='/recuperar-contrasena' element={<ForgotPassword />} />
        <Route path='/cambiar-contrasena' element={<ResetPassword />} />
        <Route path='/' element={<Home />} />
        <Route path='/busqueda' element={<Search />} />
        <Route
          path='/auth'
          element={
            <AvoidIfLoggedIn>
              <Auth />
            </AvoidIfLoggedIn>
          }
        />
        <Route
          path='/perfil'
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        <Route
          path='/mis-viajes'
          element={
            <ProtectedRoute>
              <Trips />
            </ProtectedRoute>
          }
        />

        <Route
          path='/mis-compras'
          element={
            <ProtectedRoute>
              <Purchases />
            </ProtectedRoute>
          }
        />

        <Route path='politica-de-privacidad' element={<PrivacyPolicy />} />
        <Route path='politica-de-uso-de-cookies' element={<CookiesPolicy />} />
        <Route path='terminos-y-condiciones' element={<Terms />} />

        <Route path='/anfitriones' element={<HostsWrapper />}>
          <Route index element={<HostsList />} />
          <Route path='mapa' element={<OrganizationsMap />} />
        </Route>

        <Route path='anfitriones/:id/:slug' element={<HostsDetail />} />

        <Route path='nosotros' element={<AboutUs />} />

        <Route path='/explora' element={<ExploreWrapper />}>
          <Route index element={<Navigate to='/explora/experiencias' />} />
          <Route path='experiencias'>
            <Route index element={<ExperiencesList />} />
            <Route path='mapa' element={<ExperiencesMap />} />
          </Route>

          <Route path='gastronomia'>
            <Route index element={<DishesList />} />
            <Route path='mapa' element={<ProductsMap />} />
          </Route>

          <Route path='hoteles'>
            <Route index element={<HotelsList />} />
            <Route path='mapa' element={<OrganizationsMap />} />
          </Route>

          <Route path='restaurantes'>
            <Route index element={<RestaurantsList />} />
            <Route path='mapa' element={<OrganizationsMap />} />
          </Route>
        </Route>

        <Route path='productos' element={<ProductsWrapper />}>
          <Route index element={<ProductsList />} />
          <Route path='mapa' element={<ProductsMap />} />
        </Route>

        <Route path='productos/:id/:slug' element={<ProductDetail />} />

        <Route path='experiencias'>
          <Route path=':id/:slug'>
            <Route index element={<ExperienceDetail />} />
            <Route path='reservar'>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <ExperienceCheckout />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
        </Route>

        <Route path='atractivos'>
          <Route path=':id/:slug' element={<AttractionsDetail />} />
        </Route>

        <Route path='gastronomia'>
          <Route path=':id/:slug' element={<DishesDetail />} />
        </Route>

        <Route path='alojamientos'>
          <Route path=':id/:slug' element={<LodgingDetail />} />
        </Route>

        <Route path='organizacion'>
          <Route index element={<Navigate to='/organizacion/dashboard' />} />
          <Route
            path='dashboard'
            element={
              <ProtectedRoute shouldBeOrganization>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            <Route index path=':id' element={<DashboardHome />} />

            <Route path=':id/mis-reservaciones' element={<OrganizationReservations />} />

            <Route path=':id/ordenes' element={<Orders />} />

            <Route path=':id/mis-pagos'>
              <Route index element={<PaymentForm />} />
              <Route path='facturacion' element={<BillingForm />} />
            </Route>

            <Route path=':id/configuracion'>
              <Route path='mis-pagos'>
                <Route index element={<PaymentForm />} />
                <Route path='facturacion' element={<BillingForm />} />
              </Route>
            </Route>

            <Route path=':id/mis-productos'>
              <Route index element={<OrganizationExperiences />} />

              <Route path='productos' element={<OrganizationProducts />} />
              <Route path='gastronomia' element={<OrganizationGastronomy />} />
              <Route path='alojamiento' element={<OrganizationLodging />} />
            </Route>

            <Route path=':id/equipo'>
              <Route index element={<Team />} />
            </Route>
          </Route>

          <Route path='formulario-equipo' element={<NewPartner />} />

          <Route
            path='formulario-experiencia'
            element={
              <ProtectedRoute shouldBeOrganization>
                <NewExperience />
              </ProtectedRoute>
            }
          />

          <Route
            path='formulario-producto'
            element={
              <ProtectedRoute shouldBeOrganization>
                <NewProduct />
              </ProtectedRoute>
            }
          />

          <Route
            path='formulario-gastronomia'
            element={
              <ProtectedRoute shouldBeOrganization>
                <NewGastronomy />
              </ProtectedRoute>
            }
          />

          <Route
            path='formulario-alojamiento'
            element={
              <ProtectedRoute shouldBeOrganization>
                <NewLodging />
              </ProtectedRoute>
            }
          />

          <Route
            path='registro'
            element={
              <ProtectedRoute>
                <Register />
              </ProtectedRoute>
            }
          />

          <Route
            path='evaluacion'
            element={
              <ProtectedRoute>
                <Evaluation />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path='/destinos' element={<DestinationWrapper />}>
          <Route index element={<SustainableDestinationsList />} />
          <Route path='mapa' element={<DestinationsMap />} />

          <Route path='areas-naturales'>
            <Route index element={<NaturalAreasList />} />
            <Route path='mapa' element={<NaturalAreasMap />} />
          </Route>
        </Route>

        <Route path='/destinos/:id/:slug' element={<SustainableDestinationsDetail />} />

        <Route path='/destinos/areas-naturales'>
          <Route path=':id/:slug' element={<NaturalAreasDetail />} />
        </Route>
      </SentryRoutes>
    </Suspense>
  );
}
